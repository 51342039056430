






















































































































































































































import { translations, eventHandler } from "@/mixins";
import { Location, Integrations } from "@/services/SOLO";
import VueTimepicker from "vue2-timepicker";
import StoreTimes from "./StoreTimes.vue";
import Translations from "./Translations.vue";
import { ValidationObserver, configure } from "vee-validate";
import { FadeTransition, ScaleTransition } from "vue2-transitions";
import { Component, Prop, Vue, Watch, Ref } from "vue-property-decorator";
import SelectCoordinatesModal from "./SelectCoordinatesModal.vue";
import { Select, Option } from "element-ui";
import moment from "moment";
import { LoadingPanel } from "@/components";
import { Integration } from "@/models";
interface ojbKey {
  [x: string]: string;
}

interface locationObject {
  id: string,
  name: ojbKey;
  status: string;
  telephone: string;
  pos: string;
  email: string;
  country: string;
  lat: string;
  long: string;
  "delivery-enabled": Boolean;
  "pickup-enabled": Boolean;
  "curbside-enabled": Boolean;
  "delivery-charge": string;
  "delivery-integration-id": string;
  line1: ojbKey;
  "promised-time-delta": ojbKey;
  "opening-hours": Array<any>;
}

@Component({
  components: {
    FadeTransition,
    ScaleTransition,
    Translations,
    VueTimepicker,
    StoreTimes,
    ValidationObserver,
    SelectCoordinatesModal,
    [Select.name]: Select,
    [Option.name]: Option,
  },
  mixins: [translations, eventHandler],
})
export default class LocationInfoForm extends Vue {
  @Prop() location!: any;

  $notify: any;
  confirm!: Function;
  translate!: Function;
  logistics: Array<Integration> = [];
  private arrayLang: Array<string> = ["en-us", "ar-sa"];
  private gmapModal: Boolean = false;
  private saving: Boolean = false;

  private openingHours: Array<any> = [
    { day: 0, open: "", closed: "" },
    { day: 1, open: "", closed: "" },
    { day: 2, open: "", closed: "" },
    { day: 3, open: "", closed: "" },
    { day: 4, open: "", closed: "" },
    { day: 5, open: "", closed: "" },
    { day: 6, open: "", closed: "" },
  ];

  mounted() {
    this.getLogisticPartners();
  }
  private cancel() {
    this.resetDefault()
    this.$emit("location:cancel");
  }

  private deleteLocation() {
    this.confirm(
      this.$bvModal,
      this.translate("Are you sure you want to delete this item?")
    ).then((value: boolean) => {
      if (value) {
        Location.removeLocation(this.location.id).then((response: any) => {
          this.$notify({
              title: this.translate("DATA DELETED"),
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: this.translate("Location successfully deleted"),
              type: "success",
              icon: "fas fa-trash",
            });
            this.$emit('location:deleted', this.location)
        });
      }
    });
  }

  displayLogistic(data_id: any) {
      let logistic_name = '';
      this.logistics.forEach(logistic => {
          if (logistic.id == data_id) {
              logistic_name = logistic.attributes.provider;
          }
      });
      return alert(logistic_name);
  }

  private getLogisticPartners() {
    Integrations.getIntegration('food-logistics').then((response) => {
      this.logistics = response.data.data;
      console.log(this.logistics);
    });
  }

  private selectCoordinates(coordinates: any) {
    this.location.lat = coordinates.position.lat();
    this.location.long = coordinates.position.lng();
  }

  private openGmapModal(open: Boolean = true) {
    this.gmapModal = open;
  }

  private submit(e: any) {
    if (this.saving) {
      return;
    }

    let locationClone = { ...this.location };
    locationClone["opening-hours"] = locationClone["opening-hours"].map(
      (item: any) => {
        return {
          day: item.day,
          open: moment(`${item.open}`, "hh:mm A").format("HH:mm"),
          closed: moment(`${item.closed}`, "hh:mm A").format("HH:mm"),
        };
      }
    );

    if (locationClone.id) {
      this.update(locationClone);
    } else {
      this.create(locationClone);
    }
  }

  private resetDefault() {
    this.location.name = {};
    this.location.line1 = {};
    this.location.email = ''
    this.location.lat = '';
    this.location.long = '';
    this.location.telephone = '';
  }

  private create(payload: locationObject) {
    this.saving = true;
    Location.newLocation(payload)
      .then((response: any) => {
        this.resetDefault();
        this.saving = false;
        this.$emit("location:created", response.data.data);
      })
      .catch((err: any) => {
        let errMsg: any = []
          for (let error in err.response.data.error) {
            errMsg = err.response.data.error[error]
          }
          if (errMsg.status === 500) {
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: errMsg.detail,
              type: "danger",
              icon: "fas fa-bomb",
            })
            this.$emit('modal:created', false)
          }

          if (errMsg.status === 400){
            this.$notify({
              title: "SYSTEM ERROR!",
              verticalAlign: "bottom",
              horizontalAlign: "left",
              message: errMsg.detail,
              type: "danger",
              icon: "fas fa-bomb",
            })
            this.$emit('modal:created', false)
          }


        this.saving = false
      });
  }

  private update(payload: locationObject) {
    this.saving = true;
    Location.updateLocation(payload, payload.id)
      .then((response: any) => {
        this.saving = false;
        this.$notify({
          title: this.translate("RECORDS SAVED!"),
          verticalAlign: "bottom",
          horizontalAlign: "left",
          message: this.translate("Record have been saved successfully"),
          type: "success",
          icon: "fas fa-check",
        });
        this.$emit("location:updated", response.data.data);
      })
      .catch(() => {
        this.saving = false;
      });
  }
}
